<template>
   <div id="mapContainer" style="position: relative; background: rgb(252, 249, 242); width: 624px;">

   </div>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader';
export default {
    name: 'MapComponent',
    data(){
        return{
            map: null,
            infoWindow:null
        }
    },
    methods:{
        addMarker(){
           this.map.clearMap();
           var marker = new AMap.Marker({ 
               map:this.map,					 				 
               //位置 
               position: new AMap.LngLat(116.305665,40.046553), 
               //复杂图标    
               icon: require("@/assets/image/0.png")    
           }); 

           AMap.event.addListener(marker,'click',() =>{ 
                this.infoWindow.open(this.map, marker.getPosition());
            });

           setTimeout(() => {
                this.infoWindow.open(this.map, marker.getPosition());
           }, 1000);
        },
        createInfoWindow(title,content){
            var info = document.createElement("div");
            info.className = "info";

            // 定义顶部标题
            var top = document.createElement("div");
            var titleD = document.createElement("div");
            var closeX = document.createElement("img");
            top.className = "info-top"; 
            titleD.innerHTML = title; 
            closeX.src = require("@/assets/image/close2.gif") ;
            closeX.onclick = this.closeInfoWindow;
              
            top.appendChild(titleD);
            top.appendChild(closeX);
            info.appendChild(top);
            
            
            // 定义中部内容
            var middle = document.createElement("div");
            middle.className = "info-middle";
            middle.style.backgroundColor='white';
            middle.innerHTML = content;
            info.appendChild(middle);
            
            // 定义底部内容
            var bottom = document.createElement("div");
            bottom.className = "info-bottom";
            bottom.style.position = 'relative';
            bottom.style.top = '0px';
            bottom.style.margin = '0 auto';
            var sharp = document.createElement("img");
            sharp.src = require("@/assets/image/sharp.png");
            bottom.appendChild(sharp);	
            info.appendChild(bottom);
            return info;
        },
        closeInfoWindow(){
            this.map.clearInfoWindow();
        }
    },
    mounted(){
        AMapLoader.load({
            key: '604964399548c3ff6e423da5e2be69bd', // 高德地图API Key
            plugins: [
                "AMap.Autocomplete", //输入提示插件
                "AMap.PlaceSearch", //POI搜索插件
                "AMap.Scale", //右下角缩略图插件 比例尺
                "AMap.OverView", //地图鹰眼插件
                "AMap.ToolBar", //地图工具条
                "AMap.MapType", //类别切换控件，实现默认图层与卫星图、实施交通图层之间切换的控制
                "AMap.PolyEditor", //编辑 折线多，边形
                "AMap.CircleEditor", //圆形编辑器插件
                "AMap.Geolocation" //定位控件，用来获取和展示用户主机所在的经纬度位置
            ], 
        })
        .then((AMap) => {
            this.map = new AMap.Map("mapContainer", {
                resizeEnable: true,
                mapStyle: 'amap://styles/normal',
                //二维地图显示视口
                view: new AMap.View2D({
                    //地图中心点
                    center:new AMap.LngLat(116.305665,40.046553),
                    //地图显示的缩放级别
                    zoom:10 
                }),

            });
            this.map.addControl(new AMap.ToolBar());
            this.infoWindow = new AMap.InfoWindow({
                isCustom:true,  //使用自定义窗体
                content:this.createInfoWindow('北京华鼎博视数据信息技术有限公司',"地址：北京市海淀区上地信息路2号1号楼16B <br/>电话：010-82893324"),
                offset:new AMap.Pixel(16, -45)//-113, -140
            });
            this.addMarker();
        })
        .catch(e => {
            console.log(e);
        });
    }
}
</script>

<style>
    #mapContainer{
		width:100%; height:249px;
    }
	#tip{
		height:30px;
		background-color:#fff;
		padding-left:10px;
		padding-right:10px;
		position:absolute;
		font-size:12px;
		right:10px;
		bottom:20px;
		border-radius:3px;
		line-height:30px;
		border:1px solid #ccc;
	}
	.info-top {
		position: relative;
		background: none repeat scroll 0 0 #F9F9F9;
		border-bottom: 1px solid #CCC;
		border-radius:5px 5px 0 0;
	}
	.info-top div {
		display: inline-block;
		color: #333333;
		font-size:14px;
		font-weight:bold;
		line-height:31px;
		padding:0 10px;
		text-align:left;
	}
	.info-top img {
		position: absolute;
		top: 10px;
		right: 10px;
		transition-duration: 0.25s;
	}
	.info-top img:hover{
		box-shadow: 0px 0px 5px #000;
	}
	.info-middle {
		font-size:12px;
		padding:10px;
		line-height:21px;
		text-align:left
	}
	.info-bottom {
		height:0px;
		width:100%;
		clear:both;
		text-align:center;
	}
	.info-bottom img{
		position: relative;
		z-index:104;
	}
</style>