import Vue from "vue";
import VueRouter from "vue-router";
import {Layout} from '../layout'
import store from '../store';
import Cookies from 'js-cookie'

Vue.use(VueRouter)

let routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location){
    return routerPush.call(this,location).catch(err => err);
}

const constantRouterMap = [
    {
        path:'/login',
        name:'/login',
        component:()=> import ('@/page/login/login.vue')
    },
    {
        path:'/',
        component:Layout,
        redirect:'/home',
        children:[
            {
                path:'/home',
                name:'home',
                component:()=> import('@/page/home/home.vue')
            }
            // ,
            // {
            //     path:'/chain',
            //     name:'chain',
            //     component:()=> import('@/page/chain/chain.vue')
            // }
            ,
            {
                path:'/PrincipleAdvantages',
                name:'PrincipleAdvantages',
                component:()=> import('@/page/PrincipleAdvantages/PrincipleAdvantages.vue')
            },
            {
                path:'/ValidityChecking',
                name:'ValidityChecking',
                component:() => import('@/page/ValidityChecking/ValidityChecking.vue')
            },
            {
                path:'/Personal',
                name:'Personal',
                component:() => import('@/page/Personal/Personal.vue')
            }
        ]
    }
]

const router = new VueRouter({
    mode:'history',
    routes:constantRouterMap,
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 };
    }
})


router.beforeEach((to,from,next)=>{
    const dbCookie = Cookies.get('db');
    if (dbCookie === undefined) {
        store.dispatch('setIsLogin',false);
    }
    next();
  })

export default router;