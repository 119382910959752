
const permission = {
    state: {
        isLoading:false,
        requestCount:0,
        isLogin:false
    },

    mutations: {
        //Loading
        INCREMENT_REQUEST_COUNT(state) {
          state.requestCount++
        },
        DECREMENT_REQUEST_COUNT(state) {
          state.requestCount--
        },
        SET_LOADING(state, payload) {
          state.isLoading = payload
        },
        SET_ISLOGIN(state,payload){
          state.isLogin = payload;
        }
    },
    actions: {
        incrementRequestCount({ commit }) {
          commit('INCREMENT_REQUEST_COUNT')
          commit('SET_LOADING', true)
        },
        decrementRequestCount({ commit, state }) {
          commit('DECREMENT_REQUEST_COUNT')
          if (state.requestCount === 0) {
            commit('SET_LOADING', false)
          }
        },
        setIsLogin({commit},payload){
          commit('SET_ISLOGIN',payload)
        }
    }
}

export default permission;